/** @jsx jsx */
import { jsx } from "theme-ui";
import get from "lodash/get";

import { getExhibitionPath } from "utils";
import Card from "./Card";
import Section from "./Section";

function ExhibitionsList({ heading, exhibitions, ...props }) {
  return (
    <Section heading={heading} {...props}>
      <div
        sx={{
          mx: "-m",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {exhibitions.map((exhibition) => {
          const featuredArtworkImage =
            exhibition?.image ||
            get(exhibition, ["featured_artwork", "work_image"]) ||
            get(exhibition, ["artworks", 0, "work_image"]);

          return (
            <div
              key={exhibition._id}
              sx={{ m: "m", mt: "s", maxWidth: "330px" }}
            >
              <Card
                to={getExhibitionPath(exhibition)}
                title={exhibition.name}
                subTitle={[
                  exhibition.start_date_string,
                  exhibition.end_date_string,
                ]
                  .filter(Boolean)
                  .join(" - ")}
                gatsbyImageData={featuredArtworkImage?.asset?.gatsbyImageData}
                imageAlt={exhibition.name}
              />
            </div>
          );
        })}
      </div>
    </Section>
  );
}

export default ExhibitionsList;
