/** @jsx jsx */
import { jsx } from "theme-ui";

import { getPublicationPath } from "utils";
import Section from "./Section";
import Card from "./Card";

function PublicationsList({ heading, publications, ...props }) {
  return (
    <Section heading={heading} {...props}>
      <div
        sx={{
          mx: "-m",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {publications.map((publication) => (
          <div
            key={publication._id}
            sx={{ m: "m", mt: "s", maxWidth: "330px" }}
          >
            <Card
              to={getPublicationPath(publication)}
              title={publication.title}
              subTitle={publication.publication_date_site}
              gatsbyImageData={publication.image?.asset?.gatsbyImageData}
              imageAlt={publication.title}
            />
          </div>
        ))}
      </div>
    </Section>
  );
}

export default PublicationsList;
