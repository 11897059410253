/** @jsx jsx */
import { jsx } from "theme-ui";
import ReactPortableText from "react-portable-text";

import InternalLink from "./InternalLink";
import Link from "./Link";

function PortableText({ content, ...props }) {
  return (
    <ReactPortableText
      content={content}
      serializers={{
        link: Link,
        internalLink: InternalLink,
      }}
      sx={{
        // These are technically the same, but use both
        overflowWrap: "break-word",
        wordWrap: "break-word",

        // Instead use this non-standard one:
        wordBreak: "break-word",

        // Adds a hyphen where the word breaks, if supported (No Blink)
        hyphens: "auto",
      }}
      {...props}
    />
  );
}

export default PortableText;
