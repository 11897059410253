/** @jsx jsx */
import { jsx, Label, Input, Button, Textarea, Checkbox, Text } from "theme-ui";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Fragment, useEffect } from "react";

import Field from "./Field";
import Link from "./Link";
import LoadingIndicator from "./LoadingIndicator";
import ProtectedImage from "./ProtectedImage";
import {
  sendContactForm,
  signUpToMailingList,
  getArtistName,
  getArtistLifeSpan,
} from "utils";

const styles = {
  form: {
    position: "relative",
  },

  loadingIndicator: {
    bg: "rgba(255,255,255,.5)",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  input:
    ({ isValid = true }) =>
    (_theme) =>
      isValid
        ? {}
        : {
            borderColor: "alert",
          },

  errorMessage: { mt: "xs", color: "alert" },
};

function getArtworkName(artwork) {
  return [artwork?.work_title || artwork?.name, artwork?.work_date]
    .filter(Boolean)
    .join(", ");
}

function getArtistDates(artist) {
  return getArtistLifeSpan(artist?.yearOfBirth, artist?.yearOfDeath);
}

function SelectedArtwork(artwork) {
  const artworkName = getArtworkName(artwork);
  const artistName = getArtistName(artwork.artist);
  const artistDates = getArtistDates(artwork.artist);
  return (
    <div
      sx={{
        mb: "m",
        pb: "m",
        borderBottom: "solid 1px",
        borderColor: "#ccc",
      }}
    >
      <Text as="p" sx={{ variant: "forms.label", mb: "s" }}>
        Enquiring about:
      </Text>
      <div sx={{ display: "flex", flexDirection: "row" }}>
        <div
          sx={{
            flexBasis: "186px",
            flexGrow: 0,
            flexShrink: 0,
            mr: "s",
          }}
        >
          {artwork?.image?.asset?.gatsbyImageData && (
            <ProtectedImage
              image={artwork?.image?.asset?.gatsbyImageData}
              alt={artworkName}
            />
          )}
        </div>
        <div>
          <Text as="p">
            <strong>
              {artistName}
              {artistDates && ` (${artistDates})`}
            </strong>
            <br />
            {artworkName}
          </Text>
        </div>
      </div>
    </div>
  );
}

function SelectedExhibition({ name, start_date, end_date, image }) {
  return (
    <div
      sx={{
        mb: "m",
        pb: "m",
        borderBottom: "solid 1px",
        borderColor: "#ccc",
      }}
    >
      <Text as="p" sx={{ variant: "forms.label", mb: "s" }}>
        Enquiring about:
      </Text>
      <div sx={{ display: "flex", flexDirection: "row" }}>
        <div
          sx={{
            flexBasis: "186px",
            flexGrow: 0,
            flexShrink: 0,
            mr: "s",
          }}
        >
          {image?.asset?.gatsbyImageData && (
            <ProtectedImage image={image?.asset?.gatsbyImageData} alt={name} />
          )}
        </div>
        <div>
          <Text as="p">
            <strong>{name}</strong>
            <br />
            {[start_date, end_date].filter(Boolean).join(" - ")}
          </Text>
        </div>
      </div>
    </div>
  );
}

function SelectedPublication({ title, image, author, publication_date_site }) {
  return (
    <div
      sx={{
        mb: "m",
        pb: "m",
        borderBottom: "solid 1px",
        borderColor: "#ccc",
      }}
    >
      <Text as="p" sx={{ variant: "forms.label", mb: "s" }}>
        Enquiring about:
      </Text>
      <div sx={{ display: "flex", flexDirection: "row" }}>
        <div
          sx={{
            flexBasis: "186px",
            flexGrow: 0,
            flexShrink: 0,
            mr: "s",
          }}
        >
          {image?.asset?.gatsbyImageData && (
            <ProtectedImage image={image?.asset?.gatsbyImageData} alt={name} />
          )}
        </div>
        <div>
          <Text as="p">
            <strong>{title}</strong>
            <br />
            {author} {publication_date_site}
          </Text>
        </div>
      </div>
    </div>
  );
}

function ContactForm({
  contactEmail,
  contactPhoneNumber,
  selectedArtwork,
  selectedExhibition,
  selectedPublication,
  ...props
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful, isSubmitted },
    setValue,
  } = useForm({
    reValidateMode: "onSubmit",
  });

  const onSubmit = (data) => {
    return Promise.all(
      [
        data.mailingList && signUpToMailingList({ email: data.email }),
        process.env.NODE_ENV === "production"
          ? sendContactForm({
              data: {
                ...data,
                selectedArtwork:
                  selectedArtwork &&
                  [
                    getArtworkName(selectedArtwork),
                    [
                      getArtistName(selectedArtwork.artist),
                      `(${getArtistDates(selectedArtwork.artist)})`,
                    ]
                      .filter(Boolean)
                      .join(" "),
                    `(id: ${selectedArtwork._id})`,
                  ]
                    .filter(Boolean)
                    .join(", "),
                selectedExhibition:
                  selectedExhibition &&
                  [
                    selectedExhibition?.name,
                    [
                      selectedExhibition?.start_date,
                      selectedExhibition?.end_date,
                    ]
                      .filter(Boolean)
                      .join(" - "),
                    `(id: ${selectedExhibition._id})`,
                  ]
                    .filter(Boolean)
                    .join(", "),
                selectedPublication:
                  selectedPublication &&
                  [
                    selectedPublication.title,
                    selectedPublication.publication_date_site,
                    selectedPublication.author,
                  ]
                    .filter(Boolean)
                    .join(" - "),
              },
            })
          : Promise.resolve(),
      ].filter(Boolean)
    );
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      setValue("forename", "");
      setValue("surname", "");
      setValue("email", "");
      setValue("phone", "");
      setValue("message", "");
      setValue("mailingList", false);
    }
  }, [isSubmitSuccessful]);

  return (
    <div {...props}>
      {isSubmitSuccessful && (
        <div sx={{ mb: "m" }}>
          <Text as="p" sx={{ fontSize: 3 }}>
            Your message has been sent successfully, someone will be in touch
            with you shortly.
          </Text>
        </div>
      )}

      {!isSubmitSuccessful && isSubmitted && Object.keys(errors).length === 0 && (
        <div sx={{ color: "alert", mb: "m" }}>
          <Text as="p" sx={{ fontSize: 3 }}>
            There was a problem sending your message, please try again later or
            contact us by phone or email:
            <br />
            {contactPhoneNumber && (
              <Fragment>
                <Link href={`tel:${contactPhoneNumber}`}>
                  {contactPhoneNumber}
                </Link>
                <br />
              </Fragment>
            )}
            {contactEmail && (
              <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link>
            )}
          </Text>
        </div>
      )}

      {!isSubmitSuccessful && (
        <form onSubmit={handleSubmit(onSubmit)} sx={styles.form}>
          {isSubmitting && <LoadingIndicator sx={styles.loadingIndicator} />}
          <fieldset disabled={isSubmitting} sx={{ border: "none", p: 0, m: 0 }}>
            {selectedArtwork && <SelectedArtwork {...selectedArtwork} />}

            {selectedExhibition && (
              <SelectedExhibition {...selectedExhibition} />
            )}

            {selectedPublication && (
              <SelectedPublication {...selectedPublication} />
            )}

            <Field>
              <Label htmlFor="forename">Forename:</Label>
              <div>
                <Input
                  type="text"
                  id="forename"
                  autofillBackgroundColor="#f3f3f3"
                  sx={styles.input({ isValid: !errors.forename })}
                  {...register("forename", {
                    required: "This field is required",
                  })}
                />
                <ErrorMessage
                  as="div"
                  errors={errors}
                  name="forename"
                  sx={styles.errorMessage}
                  role="alert"
                />
              </div>
            </Field>

            <Field>
              <Label htmlFor="surname">Surname:</Label>
              <div>
                <Input
                  type="text"
                  id="surname"
                  autofillBackgroundColor="#f3f3f3"
                  sx={styles.input({ isValid: !errors.surname })}
                  {...register("surname", {
                    required: "This field is required",
                  })}
                />
                <ErrorMessage
                  as="div"
                  errors={errors}
                  name="surname"
                  sx={styles.errorMessage}
                  role="alert"
                />
              </div>
            </Field>

            <Field>
              <Label htmlFor="email">Email:</Label>
              <div>
                <Input
                  type="email"
                  id="email"
                  autofillBackgroundColor="#f3f3f3"
                  sx={styles.input({ isValid: !errors.email })}
                  {...register("email", { required: "This field is required" })}
                />
                <ErrorMessage
                  as="div"
                  errors={errors}
                  name="email"
                  sx={styles.errorMessage}
                  role="alert"
                />
              </div>
            </Field>

            <Field>
              <Label htmlFor="phone">Phone:</Label>
              <div>
                <Input
                  type="tel"
                  id="phone"
                  autofillBackgroundColor="#f3f3f3"
                  sx={styles.input({ isValid: !errors.phone })}
                  {...register("phone", { required: "This field is required" })}
                />
                <ErrorMessage
                  as="div"
                  errors={errors}
                  name="phone"
                  sx={styles.errorMessage}
                  role="alert"
                />
              </div>
            </Field>

            <Field>
              <Label htmlFor="message">Message:</Label>
              <div>
                <Textarea
                  id="message"
                  autofillBackgroundColor="#f3f3f3"
                  sx={styles.input({ isValid: !errors.message })}
                  rows="3"
                  {...register("message", {
                    required: "This field is required",
                  })}
                ></Textarea>
                <ErrorMessage
                  as="div"
                  errors={errors}
                  name="message"
                  sx={styles.errorMessage}
                  role="alert"
                />
              </div>
            </Field>

            <Field>
              <Label>
                Join the mailing list?{" "}
                <Checkbox
                  defaultChecked={false}
                  {...register("mailingList")}
                  sx={{ ml: "s" }}
                />
              </Label>
            </Field>
          </fieldset>

          <div>
            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}

export default ContactForm;
