/** @jsx jsx */
import { jsx } from "theme-ui";

import { getArtistPath, getArtistName } from "utils";
import Link from "./Link";

function ArtistLink({ artist }) {
  return <Link to={getArtistPath(artist)}>{getArtistName(artist)}</Link>;
}

export default ArtistLink;
