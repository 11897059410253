/** @jsx jsx */
import { jsx } from "theme-ui";

import Heading from "./Heading";

function Section({ heading, headingAs = "h2", children, ...props }) {
  return (
    <section sx={{ mt: 0, mb: "l" }} {...props}>
      {heading && (
        <div sx={{ overflow: "hidden", mb: "m" }}>
          <Heading
            as={headingAs}
            sx={{
              display: "inline-block",
              position: "relative",

              "&:after": {
                content: '""',
                display: "block",
                height: "1px",
                bg: "#eaeaea",
                position: "absolute",
                left: "100%",
                top: "50%",
                width: "10000px",
                ml: "s",
              },
            }}
          >
            {heading}
          </Heading>
        </div>
      )}

      <div>{children}</div>
    </section>
  );
}
export default Section;
