/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import nl2br from "react-nl2br";
import truncate from "lodash/truncate";
import get from "lodash/get";

import {
  getExhibitionPath,
  getPortableTextToPlainText,
  getArtistName,
} from "utils";
import Heading from "./Heading";
import Section from "./Section";
import Link from "./Link";
import ProtectedImage from "./ProtectedImage";

const styles = {
  exhibitionsVerticalListItem: {
    display: "flex",
    flexDirection: ["column", "row"],
    mx: "-m",
  },

  imageContainer: {
    mx: "m",
    mb: ["m", 0],
    maxWidth: [null, "500px", "900px"],
  },

  contentContainer: {
    mx: "m",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: [null, "540px"],
    flexBasis: [null, "540px"],
  },

  title: {
    mb: "s",
  },

  date: { mb: "s" },

  description: {},
};

function ExhibitionsVerticalListItem({
  to,
  title,
  date,
  description,
  image,
  imageCredit,
  ...props
}) {
  return (
    <div sx={styles.exhibitionsVerticalListItem} {...props}>
      <div sx={styles.imageContainer}>
        <Link to={to}>
          <ProtectedImage
            image={image?.asset?.gatsbyImageData}
            alt={title}
            sx={{ mb: "xs" }}
          />
          {imageCredit && <Text as="p">{imageCredit}</Text>}
        </Link>
      </div>
      <div sx={styles.contentContainer}>
        {title && (
          <Heading as="h2" sx={styles.title}>
            <Link to={to}>{title}</Link>
          </Heading>
        )}
        {date && (
          <Text as="div" sx={styles.date}>
            {date}
          </Text>
        )}

        {description && (
          <Text as="p">
            {nl2br(
              truncate(getPortableTextToPlainText(description), { length: 200 })
            )}
          </Text>
        )}

        <Text as="p">
          <Link to={to}>Read more</Link>
        </Text>
      </div>
    </div>
  );
}

function ExhibitionsVerticalList({ heading, exhibitions, ...props }) {
  return (
    <Section heading={heading} {...props}>
      <div>
        {exhibitions.map((exhibition) => {
          const featuredArtwork =
            get(exhibition, ["featured_artwork"]) ||
            get(exhibition, ["artworks", 0]);

          const image =
            exhibition?.work_image__large || featuredArtwork?.work_image__large;

          const imageCredit =
            !exhibition.image &&
            [
              getArtistName(featuredArtwork.artist),
              featuredArtwork?.work_title || featuredArtwork?.name,
              featuredArtwork?.work_date,
            ]
              .filter(Boolean)
              .join(", ");

          return (
            <ExhibitionsVerticalListItem
              key={exhibition._id}
              to={getExhibitionPath(exhibition)}
              title={exhibition.name}
              date={[exhibition.start_date_string, exhibition.end_date_string]
                .filter(Boolean)
                .join(" - ")}
              description={exhibition.description}
              image={image}
              imageCredit={imageCredit}
              sx={{ mb: "xl" }}
            />
          );
        })}
      </div>
    </Section>
  );
}

export default ExhibitionsVerticalList;
