export const layout = {
  bg: "#fff",
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
};

export const header =
  ({ displayShadow = false }) =>
  (_theme) => {
    return {
      backgroundColor: "#fff",
      position: ["sticky", null, "static"],
      top: [0, null, "unset"],
      zIndex: 10,
      boxShadow: [
        displayShadow ? "0 0 20px rgba(0,0,0,.3)" : "none",
        null,
        "none",
      ],
      transition: "box-shadow .5s",
    };
  };

export const headerContainer = {
  backgroundColor: "#fff",
  position: "relative",
  py: "s",
  zIndex: 20,
  display: "flex",
  flexDirection: ["row", "column", "row"],
  justifyContent: "space-between",
  alignItems: "flex-start",
};

export const logoLink = {
  mr: "m",
  "&:hover, &:focus": {
    borderBottom: "none",
  },
};

export const navToggle = {
  display: ["block", "none", "none"],
  bg: "transparent",
  borderRadius: 0,
  borderWidth: 0,
  cursor: "pointer",
  color: "primary",
  m: 0,
  p: 0,
  position: "relative",
  zIndex: 200,
  lineHeight: 1,
  width: "32px",
  height: "32px",
  fontSize: "32px",
  fontWeight: 100,
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: "32px",

  "> svg": {
    fill: "primary",
  },
};

export const nav = {
  backgroundColor: ["rgba(255,255,255,.9)", "transparent"],
  position: ["fixed", "static"],
  top: [0, "unset"],
  left: [0],
  right: [0],
  height: ["100vh", "unset"],
  overflow: ["auto", "visible"],
  py: ["l", 0],
  px: ["l", 0],
  backdropFilter: ["blur(10px)", "unset"],
  zIndex: 100,
  mt: [null, "m", 0],
};

export const navUl = {
  display: "flex",
  flexDirection: ["column", "row"],
  listStyle: "none",
  m: 0,
  p: 0,
};

export const navLi = {
  mx: [0, "8px"],
  my: ["xs", 0],

  "&:first-of-type": {
    ml: [0, 0, "8px"],
  },

  "&:last-of-type": {
    mr: 0,
  },
};

export const footer = {
  width: "100%",
  mt: "auto",
  fontSize: "12px",
};

export const footerContainer = {
  borderTop: "solid 1px #ccc",
  py: "s",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
};

export const artworkModalButton =
  ({ direction }) =>
  (_theme) => ({
    cursor: "pointer",
    position: "absolute",
    top: ["16px", "50%"],
    [direction]: ["16px", "30px"],
    width: ["28px", "32px"],
    height: ["28px", "32px"],
    bg: "transparent",
    border: "none",
    p: 0,
    m: 0,
    mt: [0, "-16px"],

    "> svg": {
      fill: "primary",
      height: "100%",
    },
  });

export const socialLink = {
  ml: "s",
  height: "18px",
  textTransform: "uppercase",
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center",

  svg: {
    height: "18px",
    width: "18px",
    ml: "xs",
    fill: "primary",
  },

  "&:hover,&:focus": {
    svg: { fill: "secondary" },
  },
};
