/** @jsx jsx */
import { jsx } from "theme-ui";
import FocusLock from "react-focus-lock";

import CloseIcon from "./icons/CloseIcon";

export const styles = {
  modal: {
    backgroundColor: "rgba(255,255,255,.9)",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: "100vh",
    backdropFilter: "blur(10px)",
    zIndex: 1000,

    display: "flex",
    flexDirection: ["column"],
  },

  modalHeader: {
    height: ["60px", 0],
    position: ["relative", "static"],
    flexGrow: 0,
    flexShrink: 0,
    borderBottom: ["solid 1px", "none"],
    borderColor: "#ddd",
  },

  modalContent: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: "auto",
    px: ["s", "xl"],
    pt: ["m", "l"],
    pb: ["m", "l"],
  },

  closeButton: {
    display: "block",
    bg: "transparent",
    borderRadius: 0,
    borderWidth: 0,
    cursor: "pointer",
    color: "primary",
    m: 0,
    p: 0,
    position: "absolute",
    top: ["16px", "30px"],
    left: ["50%", "unset"],
    right: [null, "30px"],
    ml: "-14px",
    zIndex: 200,
    lineHeight: 1,
    width: ["28px", "32px"],
    height: ["28px", "32px"],
    fontWeight: 100,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: ["28px", "32px"],

    "> svg": {
      fill: "primary",
    },
  },
};

function Modal({ close = () => {}, headerContent, children, ...props }) {
  return (
    <FocusLock returnFocus>
      <div sx={styles.modal} {...props}>
        <div sx={styles.modalHeader}>
          {headerContent}
          {close && (
            <button
              type="button"
              onClick={close}
              aria-label="Close navigation"
              sx={styles.closeButton}
            >
              <CloseIcon />
            </button>
          )}
        </div>
        <div sx={styles.modalContent}>{children}</div>
      </div>
    </FocusLock>
  );
}

export default Modal;
