export const newsArticle = {
  display: "flex",
  flexDirection: ["column", "row"],
  mb: ["l", "xl"],
  alignItems: [null, "flex-start"],
};

export const imageContainer = {
  bg: "muted",
  flexBasis: ["100%", "240px", "320px"],
  flexGrow: 0,
  flexShrink: 0,
  mr: [null, "l"],
  mb: ["s", "0"],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const content = {
  maxWidth: "640px",
  pb: ["s", 0],
};

export const date = { color: "primary" };
