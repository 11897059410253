/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { Fragment } from "react";

import Link from "./Link";
import ProtectedImage from "./ProtectedImage";

const styles = {
  card: {
    color: "text",
    display: "block",
    textDecoration: "none",
    maxWidth: "325px",
  },

  titleContainer: {
    mt: "xs",
    position: "relative",
    pl: "s",

    "&::before": {
      content: '""',
      width: "1px",
      left: 0,
      top: "6px",
      position: "absolute",
      height: "55%",
      bg: "#ccc",
    },
  },

  title: {
    fontWeight: 600,
    m: 0,
  },

  subTitle: {
    color: "primary",
    m: 0,
  },
};

styles.cardLink = {
  ...styles.card,
  transition: "box-shadow .2s",
  p: "s",
  m: "-s",

  "&:hover, &:focus": {
    border: "none",
    position: "relative",
    zIndex: 8,
    boxShadow: "0 0 20px rgba(0,0,0,.2)",
    textDecoration: "none",
  },
};

function Card({ to, title, subTitle, gatsbyImageData, imageAlt, ...props }) {
  const innerContent = (
    <Fragment>
      {gatsbyImageData && (
        <div
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <ProtectedImage image={gatsbyImageData} alt={imageAlt} />
        </div>
      )}

      {(title || subTitle) && (
        <div sx={styles.titleContainer}>
          {title && (
            <Text as="div" sx={styles.title}>
              {title}
            </Text>
          )}
          {subTitle && (
            <Text as="div" sx={styles.subTitle}>
              {subTitle}
            </Text>
          )}
        </div>
      )}
    </Fragment>
  );
  if (to) {
    return (
      <Link to={to} {...props} sx={styles.cardLink}>
        {innerContent}
      </Link>
    );
  }
  return (
    <div {...props} sx={styles.card}>
      {innerContent}
    </div>
  );
}

export default Card;
