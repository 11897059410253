/** @jsx jsx */
import { jsx, Text } from "theme-ui";

import Link from "./Link";
import { getArtistName, getArtistPath } from "utils";

function IncludingWorksBy({ includingWorksBy = [] }) {
  return (
    <div sx={{ mt: "l" }}>
      <Text as="p" mb="0">
        Including works by:
      </Text>
      <ul
        sx={{
          columns: [2, 3, 3],
          maxWidth: "780px",
          listStyle: "none",
          m: 0,
          my: "s",
          p: 0,
        }}
      >
        {includingWorksBy.map((artist) => (
          <li key={artist._id}>
            <Link to={getArtistPath(artist)}>{getArtistName(artist)}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default IncludingWorksBy;
