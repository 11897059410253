/** @jsx jsx */
import { jsx, Grid } from "theme-ui";
import { useBreakpointIndex } from "@theme-ui/match-media";

import ArtistLink from "./ArtistLink";
import Section from "./Section";
import chunk from "lodash/chunk";

function ArtistsList({ heading, artists, ...props }) {
  const breakpointIndex = useBreakpointIndex({ defaultIndex: 1 });

  const groupedArtists = chunk(
    artists,
    Math.ceil(artists.length / [2, 3, 4, 5][breakpointIndex])
  );

  return (
    <Section heading={heading} {...props}>
      <Grid
        gap="l"
        columns={[
          "repeat(2, minmax(0, 200px))",
          "repeat(3, minmax(0, 200px))",
          "repeat(4, minmax(0, 200px))",
          "repeat(5, minmax(0, 200px))",
        ]}
        mb="xl"
        sx={{
          alignItems: "flex-start",
        }}
      >
        {groupedArtists.map((artists) => (
          <div key={artists[0]._id}>
            <ul sx={{ listStyle: "none", m: 0, mb: "m", p: 0 }}>
              {artists.map((artist) => (
                <li key={artist._id}>
                  <ArtistLink artist={artist} />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </Grid>
    </Section>
  );
}

export default ArtistsList;
