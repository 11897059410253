/** @jsx jsx */
import { jsx } from "theme-ui";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./Layout.styles";
import Link from "../Link";

function Logo({ onLogoClick }) {
  const breakpointIndex = useBreakpointIndex({ defaultIndex: 1 });

  const imageProps = {
    src: "../../images/austin-desmond-high-res.png",
    loading: "eager",
    placeholder: "none",
    alt: "Austin / Desmond Fine Art",
    height: 60,
    quality: 100,
  };

  const handleLogoLClick = (evt) => {
    if (onLogoClick) {
      evt.preventDefault();
      onLogoClick();
    }
  };

  return (
    <Link to="/" onClick={handleLogoLClick} sx={styles.logoLink}>
      {breakpointIndex >= 1 ? (
        <StaticImage {...imageProps} />
      ) : (
        <StaticImage {...imageProps} height={40} />
      )}{" "}
    </Link>
  );
}

export default Logo;
