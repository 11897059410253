/** @jsx jsx */
import { jsx, Button, Text } from "theme-ui";

import Heading from "./Heading";
import PortableText from "./PortableText";
import ProtectedImage from "./ProtectedImage";
import { getArtistLifeSpan, getArtistName } from "utils";

function Artwork({
  id,
  name,
  gatsbyImageData,
  content,
  workDate,
  artist,
  openContactForm,
}) {
  const artistDates = getArtistLifeSpan(
    artist?.yearOfBirth,
    artist?.yearOfDeath
  );

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: ["column-reverse", null, "row-reverse"],
          mx: [null, null, "-s"],
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          sx={{
            width: ["100%", null, "30%"],
            mx: [null, null, "s"],
            pb: "xxl",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Heading mb="s" sx={{ fontSize: "16px" }}>
            {getArtistName(artist)}
            {artistDates && ` (${artistDates})`}
          </Heading>

          <Text as="p" sx={{ fontStyle: "italic" }}>
            {[name, workDate].filter(Boolean).join(", ")}
          </Text>

          {content && <PortableText content={content} sx={{ mb: "m" }} />}

          {openContactForm && (
            <div
              sx={{
                mt: "auto",
                pt: "m",
                borderTop: "solid 1px #ddd",
                width: "100%",
              }}
            >
              <Button
                variant="asLink"
                type="button"
                onClick={() => openContactForm({ artworkId: id })}
                sx={{ mt: "auto" }}
              >
                Enquire
              </Button>
            </div>
          )}
        </div>

        <div
          sx={{
            display: "flex",
            mx: [null, null, "s"],
            mb: "s",
          }}
        >
          {gatsbyImageData && (
            <ProtectedImage image={gatsbyImageData} alt={name} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Artwork;
