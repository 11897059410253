/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql, useStaticQuery } from "gatsby";

function SidebarLayout({ aside, children, ...props }) {
  const {
    allSanityAnnouncement: { announcements },
  } = useStaticQuery(graphql`
    {
      allSanityAnnouncement(filter: { display_announcement: { eq: true } }) {
        announcements: edges {
          node {
            title
          }
        }
      }
    }
  `);

  const hasAnnouncement = announcements.length > 0;

  const top = [
    hasAnnouncement ? "108px" : "72px",
    hasAnnouncement ? "182px" : "147px",
    "unset",
  ];

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", null, "row"],
        mx: [null, null, "-m"],
      }}
      {...props}
    >
      {aside && (
        <aside
          sx={{
            bg: ["white", null, "transparent"],
            border: "solid 0px",
            borderBottomWidth: ["1px", null, 0],
            borderColor: "#ccc",
            flexBasis: [null, null, "160px"],
            flexGrow: 0,
            flexShrink: 0,
            mt: 0,
            mb: ["m", null, 0],
            mx: ["-s", "-m", "m"],
            position: ["sticky", null, "relative"],
            top,
            zIndex: 9,
          }}
        >
          <div
            sx={{
              position: [null, null, "sticky"],
              top: [null, null, "20px"],
              height: ["46px", null, "unset"],
              overflow: ["hidden", null, "unset"],
            }}
          >
            <div sx={{ overflow: ["auto", null, "unset"] }}>{aside}</div>
          </div>
        </aside>
      )}

      <div sx={{ width: "100%", mx: [null, null, "m"] }}>{children}</div>
    </div>
  );
}

export default SidebarLayout;
