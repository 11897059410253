/** @jsx jsx */
import { jsx } from "theme-ui";
import { keyframes } from "@emotion/react";

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`;

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  div: {
    position: "relative",
    my: "0",
    mx: "auto",
    width: "50px",

    "&:before": {
      content: "''",
      display: "block",
      paddingTop: "100%",
    },
  },

  svg: {
    animation: `${rotate} 2s linear infinite`,
    height: "100%",
    transformOrigin: "center center",
    width: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },

  circle: {
    stroke: "primary",
    strokeDasharray: "1, 200",
    strokeDashoffset: 0,
    animation: `${dash} 1.5s ease-in-out infinite`,
    strokeLinecap: "round",
  },
};

function LoadingIndicator({ sx = {}, ...props }) {
  return (
    <div sx={{ ...styles.container, ...sx }} {...props}>
      <div sx={styles.div}>
        <svg viewBox="25 25 50 50" sx={styles.svg}>
          <circle
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="4"
            strokeMiterlimit="10"
            sx={styles.circle}
          />
        </svg>
      </div>
    </div>
  );
}

export default LoadingIndicator;
