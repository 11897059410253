/** @jsx jsx */
import { jsx } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";

function ProtectedImage({ sx, ...props }) {
  return (
    <div
      sx={{
        ...sx,
        position: "relative",
        "::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
        },
      }}
    >
      <GatsbyImage
        {...props}
        sx={{ pointerEvents: "none" }}
        draggable="false"
      />
    </div>
  );
}

export default ProtectedImage;
