/** @jsx jsx */
import { jsx } from "theme-ui";
import { Fragment, useState, useEffect, useCallback } from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

import Heading from "./Heading";
import Modal from "./Modal";
import PortableText from "./PortableText";
import ProtectedImage from "./ProtectedImage";
import { ChevronLeft, ChevronRight } from "./icons";

const styles = {
  modalButton:
    ({ direction }) =>
    (_theme) => ({
      cursor: "pointer",
      position: "absolute",
      top: ["16px", "50%"],
      [direction]: ["16px", "30px"],
      width: ["28px", "32px"],
      height: ["28px", "32px"],
      bg: "transparent",
      border: "none",
      p: 0,
      m: 0,
      mt: [0, "-16px"],

      "> svg": {
        fill: "primary",
        height: "100%",
      },
    }),
};

function InstallationShots({
  initialInstalltionShotId,
  installationShots = [],
  closeInstallationShots,
}) {
  const location = useLocation();

  const initialSelectedIndex = Math.max(
    installationShots.findIndex(
      (installationShot) => installationShot._id === initialInstalltionShotId
    ),
    0
  );

  const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);

  const setPreviousIndex = useCallback(() => {
    setSelectedIndex(
      (selectedIndex + installationShots.length - 1) % installationShots.length
    );
  }, [selectedIndex, installationShots.length]);

  const setNextIndex = useCallback(() => {
    setSelectedIndex(
      (selectedIndex + installationShots.length + 1) % installationShots.length
    );
  }, [selectedIndex, installationShots.length]);

  useEffect(() => {
    const handleKeyDown = ({ key }) => {
      if (key === "Escape") {
        closeInstallationShots();
      }

      if (key === "ArrowRight") {
        setNextIndex();
      }

      if (key === "ArrowLeft") {
        setPreviousIndex();
      }
    };

    window.document.addEventListener("keydown", handleKeyDown);

    return () => {
      window.document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setPreviousIndex, setNextIndex]);

  useEffect(() => {
    return () => {
      navigate(location.pathname);
    };
  }, []);

  const installationShot = installationShots[selectedIndex];

  return (
    <Modal
      close={closeInstallationShots}
      sx={{ zIndex: 2000 }}
      headerContent={
        installationShots.length > 1 && (
          <Fragment>
            <button
              type="button"
              onClick={setPreviousIndex}
              sx={styles.modalButton({ direction: "left" })}
            >
              <ChevronLeft />
            </button>
            <button
              type="button"
              onClick={setNextIndex}
              sx={styles.modalButton({ direction: "right" })}
            >
              <ChevronRight />
            </button>
          </Fragment>
        )
      }
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          mx: [null, null, "auto"],
          mb: "s",
          alignItems: "center",
          maxWidth: "1200px",
        }}
      >
        {installationShot.image.asset.gatsbyImageData && (
          <ProtectedImage
            image={installationShot.image.asset.gatsbyImageData}
            alt={installationShot.name}
            sx={{ mb: "m" }}
          />
        )}

        <div>
          {installationShot.name && (
            <Heading mb="s" sx={{ fontSize: "16px" }}>
              {installationShot.name}
            </Heading>
          )}

          {installationShot.description && (
            <PortableText
              content={installationShot.description}
              sx={{ mb: "m" }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}

export default InstallationShots;
