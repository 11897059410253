import React from "react";

import Link from "./Link";
import {
  getArtistPath,
  getArtworkPath,
  getExhibitionPath,
  getNewsArticlePath,
  getPublicationPath,
  getViewingRoomPath,
} from "../utils";

function InternalLink({ children, reference }) {
  if (reference._type === "download" && reference.file) {
    return (
      <Link href={reference.file.asset.url} target="_blank" rel="noreferrer">
        {children}
      </Link>
    );
  }

  if (reference._type === "artist" && reference.create_web_page) {
    return <Link to={getArtistPath(reference)}>{children}</Link>;
  }

  if (reference._type === "arwork") {
    return <Link to={getArtworkPath(reference)}>{children}</Link>;
  }

  if (reference._type === "exhibition") {
    return <Link to={getExhibitionPath(reference)}>{children}</Link>;
  }

  if (reference._type === "publication") {
    return <Link to={getPublicationPath(reference)}>{children}</Link>;
  }

  if (reference._type === "viewing_room") {
    return <Link to={getViewingRoomPath(reference)}>{children}</Link>;
  }

  if (reference._type === "about_page") {
    return <Link to={`/about`}>{children}</Link>;
  }

  if (reference._type === "home_page") {
    return <Link to={`/`}>{children}</Link>;
  }

  if (reference._type === "contact_page") {
    return <Link to={`/contact`}>{children}</Link>;
  }

  if (reference._type === "news_article") {
    return <Link to={getNewsArticlePath(reference)}>{children}</Link>;
  }

  if (reference._type === "download") {
    return (
      <Link to={reference?.file?.asset?.url || ""} download target="_blank">
        {children}
      </Link>
    );
  }

  if (reference._type === "installationShot") {
    return (
      <Link
        to={`${getExhibitionPath(reference.forExhibition)}#${reference._id}`}
      >
        {children}
      </Link>
    );
  }

  if (process.env.NODE_ENV === "development") {
    console.log("internalLink", { children, reference });
  }

  return children;
}

export default InternalLink;
