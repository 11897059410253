/** @jsx jsx */
import { jsx, Text } from "theme-ui";

import * as styles from "./NewsArticle.styles";
import { getNewsArticleSlug } from "utils";
import { Heading, PortableText, ProtectedImage } from "../components";

function NewsArticle({ title, date, image, content }) {
  return (
    <div sx={styles.newsArticle} id={getNewsArticleSlug({ date, title })}>
      <div sx={styles.imageContainer}>
        {image?.asset?.gatsbyImageData && (
          <ProtectedImage
            image={image.asset.gatsbyImageData}
            alt={title}
            draggable="false"
          />
        )}
      </div>
      <div sx={styles.content}>
        <Heading as="h2">{title}</Heading>
        <Text as="p" sx={styles.date}>
          {date}
        </Text>

        {content && <PortableText content={content} />}
      </div>
    </div>
  );
}

export default NewsArticle;
