/* eslint-disable react/jsx-no-target-blank */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link as GatsbyLink } from "gatsby";

function Link({ href, blank = false, variant = "links.primary", ...props }) {
  if (href) {
    return (
      <a
        href={href}
        target={blank ? "_blank" : undefined}
        rel={blank ? "noreferrer" : undefined}
        {...props}
        sx={{
          variant,
        }}
      />
    );
  }

  return (
    <GatsbyLink
      {...props}
      activeClassName="active"
      sx={{
        variant,
      }}
    />
  );
}

export default Link;
