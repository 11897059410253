/** @jsx jsx */
import { jsx } from "theme-ui";

import NewsArticle from "./NewsArticle";
import Section from "./Section";

function NewsList({ heading, newsArticles, ...props }) {
  return (
    <Section heading={heading} {...props}>
      {newsArticles.map(({ _id, date, image, title, _rawContent }) => (
        <NewsArticle
          key={_id}
          title={title}
          date={date}
          image={image}
          content={_rawContent}
        />
      ))}
    </Section>
  );
}

export default NewsList;
