/** @jsx jsx */
import { jsx } from "theme-ui";

function Field({ children, ...props }) {
  return (
    <div sx={{ mb: "m" }} {...props}>
      {children}
    </div>
  );
}

export default Field;
