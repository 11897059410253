/** @jsx jsx */
import { jsx } from "theme-ui";
import get from "lodash/get";

import { getViewingRoomPath } from "utils";
import Card from "./Card";
import Section from "./Section";

function ViewingRoomsList({ heading, viewingRooms, ...props }) {
  return (
    <Section heading={heading} {...props}>
      <div
        sx={{
          mx: "-m",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {viewingRooms.map((viewingRoom) => {
          const featuredArtwork = get(viewingRoom, ["artworks", 0]);

          return (
            <div
              key={viewingRoom._id}
              sx={{ m: "m", mt: "s", maxWidth: "330px" }}
            >
              <Card
                to={getViewingRoomPath(viewingRoom)}
                title={viewingRoom.title}
                subTitle={viewingRoom.date_string}
                gatsbyImageData={
                  featuredArtwork &&
                  featuredArtwork?.work_image?.asset?.gatsbyImageData
                }
                imageAlt={viewingRoom.title}
              />
            </div>
          );
        })}
      </div>
    </Section>
  );
}

export default ViewingRoomsList;
