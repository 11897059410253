/** @jsx jsx */
import { jsx } from "theme-ui";

import Heading from "./Heading";
import VisuallyHidden from "./VisuallyHidden";

function PageHeading({ heading, children, ...props }) {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", "row"],
        justifyContent: "space-between",
        alignItems: "center",
        mb: children ? "m" : 0,
      }}
      {...props}
    >
      <VisuallyHidden>
        <Heading>{heading}</Heading>
      </VisuallyHidden>
      {children && (
        <div sx={{ ml: [null, "auto"], mt: ["s", 0] }}>{children}</div>
      )}
    </div>
  );
}

export default PageHeading;
