/** @jsx jsx */
import { jsx } from "theme-ui";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { useState } from "react";
import { Link as ScrollLink } from "react-scroll";

import SidebarLayout from "./SidebarLayout";

function ListItem({ children }) {
  return (
    <li
      sx={{
        mb: [0, null, "xs"],
        mx: ["s", null, 0],
      }}
    >
      {children}
    </li>
  );
}

const styles = {
  scrollLink:
    ({ isActive = false }) =>
    (_theme) => ({
      variant: "links.primary",
      whiteSpace: ["nowrap", null, "unset"],
      ...(isActive ? { fontWeight: "700" } : {}),
      fontSize: 2,
      pl: "s",
      position: "relative",
      display: "inline-block",

      "&:before": {
        content: '""',
        display: ["none", null, "block"],
        height: "1px",
        width: isActive ? "10px" : "5px",
        bg: "primary",
        position: "absolute",
        right: "100%",
        top: "50%",
        transition: "width .1s",
        mr: "-5px",
      },
    }),
};

function ScrollLayout({ sections, ...props }) {
  const breakpointIndex = useBreakpointIndex({ defaultIndex: 1 });
  const [activeId, setActiveId] = useState(sections[0].id);

  return (
    <SidebarLayout
      aside={
        sections.length > 1 && (
          <ul
            sx={{
              m: 0,
              px: 0,
              pt: ["11px", null, 0],
              pb: ["l", null, 0],
              listStyle: "none",
              display: "flex",
              flexDirection: ["row", null, "column"],
              float: ["left", null, "unset"],
            }}
          >
            {sections.map(({ id, title }) => (
              <ListItem key={id}>
                <ScrollLink
                  href={`#${id}`}
                  to={id}
                  sx={styles.scrollLink({ isActive: activeId === id })}
                  spy={true}
                  smooth={true}
                  offset={[-158, -158, -20, -20][breakpointIndex]}
                  duration={500}
                  onSetActive={() => setActiveId(id)}
                >
                  {title}
                </ScrollLink>
              </ListItem>
            ))}
          </ul>
        )
      }
      {...props}
    >
      {sections.map(({ children }) => children)}
    </SidebarLayout>
  );
}

export default ScrollLayout;
