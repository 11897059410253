/** @jsx jsx */
import { jsx, Container, Text } from "theme-ui";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import isFunction from "lodash/isFunction";
import compose from "lodash/fp/compose";
import map from "lodash/fp/map";
import get from "lodash/fp/get";
import head from "lodash/fp/head";
import debounce from "lodash/debounce";

import * as styles from "./Layout.styles";
import Artwork from "../Artwork";
import Link from "../Link";
import { ChevronLeft, ChevronRight, CloseIcon, NavIcon } from "../icons";
import Logo from "./Logo";
import ContactForm from "../ContactForm";
import InstallationShots from "../InstallationShots";
import Modal from "../Modal";
import { getSlug, getIsShortScreen } from "utils";
import { Instagram, Twitter } from "../icons";

const navLinks = [
  { to: "/artists", children: "Artists", partiallyActive: true },
  { to: "/exhibitions", children: "Exhibitions", partiallyActive: true },
  { to: "/publications", children: "Publications", partiallyActive: true },
  { to: "/viewing-rooms", children: "Viewing Rooms", partiallyActive: true },
  { to: "/news", children: "News", partiallyActive: true },
  { to: "/about", children: "About", partiallyActive: true },
  { to: "/contact", children: "Contact", partiallyActive: true },
];

const Layout = ({ pageTitle = [], artworks = [], onLogoClick, children }) => {
  const isShortScreen = getIsShortScreen();
  const breakpointIndex = useBreakpointIndex({ defaultIndex: 1 });
  const [displayShadow, setDisplayShadow] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [renderContactForm, setRenderContactForm] = useState(false);
  const [renderArtworkModal, setRenderArtworkModal] = useState(false);
  const [renderInstallationShots, setRenderInstallationShots] = useState(false);
  const [selectedArtworkId, setSelectedArtworkId] = useState(null);
  const [selectedExhibition, setSelectedExhibition] = useState(null);
  const [selectedPublication, setSelectedPublication] = useState(null);
  const [selectedInstallationShotId, setSelectedInstallationShotId] =
    useState(null);
  const [selectedInstallationShots, setSelectedInstallationShots] =
    useState(null);

  const [_windowHeight, setWindowHeight] = useState(1000);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }
    const debouncedHandleResize = debounce(handleResize, 300);
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  const {
    allSanityAnnouncement: { announcements },
    sanitySiteSettings: {
      contactEmail,
      contactPhoneNumber,
      instagramUrl,
      twitterUrl,
    },
  } = useStaticQuery(graphql`
    {
      allSanityAnnouncement(filter: { display_announcement: { eq: true } }) {
        announcements: edges {
          node {
            title
          }
        }
      }
      sanitySiteSettings {
        contactEmail: contact_email
        contactPhoneNumber: contact_phone_number
        instagramUrl: instagram_url
        twitterUrl: twitter_url
      }
    }
  `);

  const announcment = compose(head, map(get("node")))(announcements);

  const selectedArtworkIndex = artworks.findIndex(
    ({ _id }) => _id === selectedArtworkId
  );
  const selectedArtwork = artworks[selectedArtworkIndex];

  const setNextSelectedArtworkId = () => {
    setSelectedArtworkId(
      artworks[(selectedArtworkIndex + artworks.length + 1) % artworks.length]
        ._id
    );
  };

  const setPreviousSelectedArtworkId = () => {
    setSelectedArtworkId(
      artworks[(selectedArtworkIndex + artworks.length - 1) % artworks.length]
        ._id
    );
  };

  const openInstallationShots = ({ installtionShotId, installationShots }) => {
    setSelectedInstallationShotId(installtionShotId);
    setSelectedInstallationShots(installationShots);
    setRenderInstallationShots(true);
  };

  const closeInstallationShots = () => {
    setRenderInstallationShots(false);
    setSelectedInstallationShots(null);
    setSelectedInstallationShotId(null);
  };

  const openContactForm = ({ artworkId, exhibition, publication }) => {
    setSelectedArtworkId(artworkId);
    setSelectedExhibition(exhibition);
    setSelectedPublication(publication);
    setRenderContactForm(true);
  };

  const closeContactForm = () => {
    setRenderContactForm(false);
  };

  const openArtworkModal = ({ artworkId = null }) => {
    setSelectedArtworkId(artworkId);
    setRenderArtworkModal(true);
  };

  const closeArtworkModal = () => {
    setRenderArtworkModal(false);
    setSelectedArtworkId(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      setDisplayShadow((window?.scrollY || 0) > 32);
    };
    window?.addEventListener("scroll", handleScroll);
    setDisplayShadow((window?.scrollY || 0) > 32);
    return () => window?.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.document.body.style.overflow = isNavVisible ? "hidden" : "auto";
  }, [isNavVisible]);

  useEffect(() => {
    const handleKeyDown = ({ key }) => {
      if (key === "Escape" && renderContactForm) {
        closeContactForm();
      } else if (key === "Escape" && selectedArtwork) {
        closeArtworkModal();
      }

      if (!renderContactForm && renderArtworkModal && key === "ArrowRight") {
        setNextSelectedArtworkId();
      }

      if (!renderContactForm && renderArtworkModal && key === "ArrowLeft") {
        setPreviousSelectedArtworkId();
      }
    };

    const setBodyOverflow = () => {
      window.document.body.style.overflow =
        renderContactForm || selectedArtwork || renderInstallationShots
          ? "hidden"
          : "auto";
    };

    window.document.addEventListener("keydown", handleKeyDown);
    setBodyOverflow();

    return () => {
      window.document.removeEventListener("keydown", handleKeyDown);
      setBodyOverflow();
    };
  }, [renderContactForm, selectedArtwork, renderInstallationShots]);

  return (
    <Fragment>
      {renderInstallationShots && (
        <InstallationShots
          initialInstalltionShotId={selectedInstallationShotId}
          installationShots={selectedInstallationShots}
          closeInstallationShots={closeInstallationShots}
        />
      )}

      {renderContactForm && (
        <Modal close={closeContactForm} sx={{ zIndex: 2000 }}>
          <ContactForm
            contactEmail={contactEmail}
            contactPhoneNumber={contactPhoneNumber}
            selectedArtwork={selectedArtwork}
            selectedExhibition={selectedExhibition}
            selectedPublication={selectedPublication}
            sx={{ maxWidth: "640px", mx: "auto" }}
          />
        </Modal>
      )}

      {renderArtworkModal && (
        <Modal
          close={closeArtworkModal}
          headerContent={
            artworks.length > 1 && (
              <Fragment>
                <button
                  type="button"
                  onClick={setPreviousSelectedArtworkId}
                  sx={styles.artworkModalButton({ direction: "left" })}
                >
                  <ChevronLeft />
                </button>
                <button
                  type="button"
                  onClick={setNextSelectedArtworkId}
                  sx={styles.artworkModalButton({ direction: "right" })}
                >
                  <ChevronRight />
                </button>
              </Fragment>
            )
          }
        >
          <Artwork
            id={selectedArtwork?._id}
            name={selectedArtwork?.work_title || selectedArtwork?.name}
            medium={selectedArtwork?.medium?.medium}
            gatsbyImageData={
              isShortScreen
                ? selectedArtwork?.image__short?.asset?.gatsbyImageData
                : selectedArtwork?.image?.asset?.gatsbyImageData
            }
            content={selectedArtwork?.content}
            workDate={selectedArtwork?.work_date}
            artist={selectedArtwork?.artist}
            openContactForm={openContactForm}
          />
        </Modal>
      )}

      <div sx={styles.layout}>
        <Helmet>
          <title>
            {[
              ...(typeof pageTitle === "string" ? [pageTitle] : pageTitle),
              "Austin / Desmond Fine Art",
            ]
              .filter(Boolean)
              .join(" - ")}
          </title>
        </Helmet>

        <header sx={styles.header({ displayShadow })}>
          {announcment && (
            <div
              sx={{
                bg: "secondary",
                color: "white",
                p: "xxs",
                mb: "xxs",
                textAlign: "center",
              }}
            >
              <Link
                to={`/${getSlug(announcment.title)}`}
                sx={{ color: "inherit" }}
              >
                {announcment.title}
              </Link>
            </div>
          )}

          <Container sx={styles.headerContainer}>
            <Logo onLogoClick={onLogoClick} />

            <button
              type="button"
              onClick={() => setIsNavVisible(!isNavVisible)}
              aria-label={isNavVisible ? "Close navigation" : "Open navigation"}
              sx={styles.navToggle}
            >
              {isNavVisible ? <CloseIcon /> : <NavIcon />}
            </button>

            {(breakpointIndex >= 1 || isNavVisible) && (
              <nav sx={styles.nav}>
                <ul sx={styles.navUl}>
                  {navLinks.map((navLink) => (
                    <li key={navLink.to} sx={styles.navLi}>
                      <Link {...navLink} variant="links.header" />
                    </li>
                  ))}
                </ul>
              </nav>
            )}
          </Container>
        </header>

        <main sx={{ py: "m" }}>
          <Container>
            {isFunction(children)
              ? children({
                  openContactForm,
                  closeContactForm,
                  openArtworkModal,
                  closeArtworkModal,
                  openInstallationShots,
                  closeInstallationShots,
                })
              : children}
          </Container>
        </main>

        <footer sx={styles.footer}>
          <Container>
            <div sx={styles.footerContainer}>
              <Text
                variant="copyright"
                as="p"
                mb="s"
                sx={{ textAlign: "left" }}
              >
                copyright &copy; {new Date().getFullYear()} Austin / Desmond
                Fine Art
              </Text>

              <div sx={{ textAlign: "right" }}>
                <div>
                  {twitterUrl && (
                    <Link href={twitterUrl} blank sx={styles.socialLink}>
                      Twitter <Twitter />
                    </Link>
                  )}

                  {instagramUrl && (
                    <Link href={instagramUrl} blank sx={styles.socialLink}>
                      Instagram <Instagram />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </footer>
      </div>
    </Fragment>
  );
};

export default Layout;
