/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import nl2br from "react-nl2br";
import truncate from "lodash/truncate";

import { getViewingRoomPath, getPortableTextToPlainText } from "utils";
import Heading from "./Heading";
import Section from "./Section";
import Link from "./Link";
import ProtectedImage from "./ProtectedImage";

const styles = {
  viewingRoomsVerticalListItem: {
    display: "flex",
    flexDirection: ["column", "row"],
    mx: "-m",
  },

  imageContainer: {
    mx: "m",
    mb: ["m", 0],
    maxWidth: [null, "500px", "900px"],
  },

  contentContainer: {
    mx: "m",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: [null, "540px"],
    flexBasis: [null, "540px"],
  },

  title: {
    mb: "s",
  },

  date: { mb: "s" },

  description: {},
};

function ViewingRoomsVerticalListItem({
  to,
  title,
  date,
  description,
  gatsbyImageData,
  ...props
}) {
  return (
    <div sx={styles.viewingRoomsVerticalListItem} {...props}>
      <div sx={styles.imageContainer}>
        <Link to={to}>
          <ProtectedImage image={gatsbyImageData} alt={title} />
        </Link>
      </div>
      <div sx={styles.contentContainer}>
        {title && (
          <Heading as="h2" sx={styles.title}>
            <Link to={to} sx={{ color: "inherit" }}>
              {title}
            </Link>
          </Heading>
        )}
        {date && (
          <Text as="div" sx={styles.date}>
            {date}
          </Text>
        )}

        {description && (
          <Text as="p">
            {nl2br(
              truncate(getPortableTextToPlainText(description), { length: 200 })
            )}
          </Text>
        )}

        <Text as="p">
          <Link to={to}>Read more</Link>
        </Text>
      </div>
    </div>
  );
}

function ViewingRoomsVerticalList({ heading, viewingRooms, ...props }) {
  return (
    <Section heading={heading} {...props}>
      <div>
        {viewingRooms.map((viewingRoom) => (
          <ViewingRoomsVerticalListItem
            key={viewingRoom._id}
            to={getViewingRoomPath(viewingRoom)}
            title={viewingRoom.title}
            date={viewingRoom.date}
            description={viewingRoom.content}
            gatsbyImageData={
              viewingRoom.artworks[0].work_image__large?.asset?.gatsbyImageData
            }
            imageAlt={viewingRoom.name}
            sx={{ mb: "xl" }}
          />
        ))}
      </div>
    </Section>
  );
}

export default ViewingRoomsVerticalList;
