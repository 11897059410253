/** @jsx jsx */
import { jsx } from "theme-ui";

function CloseIcon() {
  return (
    <svg
      viewBox="0 0 224.512 224.512"
      xmlns="http://www.w3.org/2000/svg"
      fill="#fff"
      strokeWidth="0"
    >
      <path d="m224.507 6.997-6.986-6.997-105.265 105.258-105.258-105.258-6.993 6.997 105.258 105.257-105.258 105.258 6.993 7 105.258-105.272 105.265 105.272 6.986-7-105.258-105.258z" />
    </svg>
  );
}

export default CloseIcon;
